<template>
    <div class="box">
        <div class="null-page" v-show="yes"></div>

        <div class="box-card order-list" v-loading="loading">
            <div slot="header" class="clearfix"><span>我的订单</span></div>
            <div class="order_serch">
                <el-input style="width: 200px;" size="small" placeholder="请输入订单编号/业务员" v-model="order_no" clearable></el-input>
                <!-- 下单时间 -->
                <el-date-picker size="small" v-model="order_time" type="daterange" range-separator="至" start-placeholder="下单开始时间" end-placeholder="下单结束日期" value-format="yyyy-MM-dd"></el-date-picker>
                <el-button size="small" type="primary" @click="search">搜索</el-button>
            </div>

            <el-tabs v-model="orderStatus" @tab-click="handleClick">
                <template v-for="item in tab_options">
                    <el-tab-pane :label="item.label" :name="item.value"></el-tab-pane>
                </template>
            </el-tabs>
            <div class="order_list_box">
                <div class="order_list_header">
                    <template v-for="item in order_list_header">
                        <div>
                            <span>{{ item.text }}</span>
                        </div>
                    </template>
                </div>
                <template v-for="(orderItem, orderIndex) in orderList">
                    <div class="order_list_content">
                    <div>
                        <div>
                            <span>订单编号：</span>
                            <span class="text_color1">{{ orderItem.order_no }}</span>
                        </div>
                        <div>
                            <span>下单时间：{{ $util.timeStampTurnTime(orderItem.create_time) }}</span>
                        </div>
                    </div>
                    <div>
                        <div>
                            <span>￥{{ orderItem.goods_money }}</span>
                        </div>
                        <div>
                            <span>￥{{ orderItem.delivery_money }}</span>
                        </div>
                        <div>
                            <span>￥{{ orderItem.pay_money }}</span>
                        </div>
                        <div>
                            <span>￥{{ orderItem.balance_money }}</span>
                        </div>
                        <div>
                            <span>￥{{ returnYouhui(orderItem) }}</span>
                        </div>
                        <div>
                            <span>￥{{ orderItem.promotion_money }}</span>
                        </div>
                        <div>
                            <span v-if="orderItem.saler_info&&orderItem.saler_info.fenxiao_name">{{ orderItem.saler_info.fenxiao_name }}</span>
                            <span v-else>无</span>
                        </div>
                        <div>
                            <span class="text_color1">{{ orderItem.order_status_name }}</span>
                        </div>
                        <div @click="orderDetail(orderItem)">
                            <span class="text_color2">订单详情</span>
                        </div>
                    </div>
                </div>
                </template>
                
                <div class="order_list_empty" v-if="orderList.length===0">
                    <span>暂无订单数据</span>
                </div>
            </div>
            <div class="pager">
                <el-pagination background :pager-count="5" :total="total" prev-text="上一页" next-text="下一页"
                    :current-page.sync="currentPage" :page-size.sync="pageSize" @size-change="handlePageSizeChange"
                    @current-change="handleCurrentPageChange" hide-on-single-page></el-pagination>
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from "vuex";
import { apiOrderList } from "@/api/order/order";
import orderMethod from "@/utils/orderMethod";
import CountDown from "vue2-countdown";
import ctrlShowText from "@/components/ctrl-show-text/ctrl-show-text.vue";

export default {
    name: "order_list",
    components: {
        CountDown,
        ctrlShowText
    },
    data: () => {
        return {
            orderStatus: "all",
            tab_options:[
                {label:'全部订单',value:'all'},
                {label:'待付款',value:'waitpay'},
                {label:'待发货',value:'waitsend'},
                {label:'待收货',value:'waitconfirm'},
                {label:'已完成',value:'waitrate'},
                {label:'已关闭',value:'closed'},
                {label:'待退款',value:'waitrefund'},
                // {label:'待评价',value:'waitrate'}
            ],
            order_list_header:[
                // {text:'订单编号',width:'100px'},
                // {text:'下单时间',width:'70px'},
                {text:'商品总金额'},
                {text:'邮费'},
                {text:'实付总金额'},
                {text:'余额支付'},
                {text:'优惠券'},
                {text:'满减优惠'},
                {text:'业务员'},
                {text:'订单状态'},
                {text:'操作'},
            ],
            loading: true,
            orderList: [],
            currentPage: 1,
            pageSize: 10,
            total: 0,
            yes: true,
            isMouseDown: false,
            startX: 0,
            scrollLeft: 0,
            order_no: "",
            order_time: []
        }
    },
    mixins: [orderMethod],
    created() {
        this.orderStatus = this.$route.query.status || "all"
        this.getOrderList()
    },
    computed: {
        ...mapGetters(["defaultGoodsImage"]),
        orderTotalMoney() {
            return (orderItem) => {
                // orderItem.order_goods.forEach()
                let res = orderItem.order_goods.reduce((pre, cur) => {
                    return pre + cur.price * cur.num
                }, 0)
                return res.toFixed(2)
            }
        }
    },
    mounted() {
        let self = this
        setTimeout(function () {
            self.yes = false
        }, 300)
    },
    methods: {
        search(){
            this.currentPage = 1
            this.getOrderList()
        },
        returnStyle(info){
            if(info.width){
                return `width: ${info.width};flex-shrink: 0;`
            }
            return '';
        },
        returnYouhui(orderItem){
            let discounts= (Number(orderItem.goods_money) + Number( orderItem.delivery_money) ) - Number(orderItem.pay_money)
            return discounts.toFixed(2);
        },
        returnShowMoreInfo(goodsItem){
            let bool = ((goodsItem.promotion_type_name.includes('秒杀') || goodsItem.promotion_type_name.includes('折扣')|| goodsItem.promotion_label==='wholesale') && goodsItem.num > goodsItem.discount_goods_limit);
            if(bool){
                return 'display: flex;flex-direction: column;line-height: 20px;padding-top: 12px;'
            }
            return '';
        },
        handleMouseDown(index, e) {
            this.isMouseDown = true;
            const tabsContainer = this.$refs['tabsContainer' + index][0];
            this.startX = e.pageX - tabsContainer.offsetLeft;
            this.scrollLeft = tabsContainer.scrollLeft;
        },
        handleMouseMove(index, e) {
            if (!this.isMouseDown) return;
            e.preventDefault();
            const tabsContainer = this.$refs['tabsContainer' + index][0];
            if (tabsContainer) {
                const x = e.pageX - tabsContainer.offsetLeft;
                const walk = (x - this.startX) * 1; // 调整滚动速度
                tabsContainer.scrollLeft = this.scrollLeft - walk;
            }
        },
        handleMouseUp() {
            this.isMouseDown = false;
        },
        handleClick(tab, event) {
            this.currentPage = 1
            this.orderStatus = tab.name
            this.refresh()
        },
        getOrderList() {
            let postObj = {
                page: this.currentPage,
                page_size: this.pageSize,
                order_status: this.orderStatus
            };
            if(this.order_no){
                postObj.keyword = this.order_no
            }
            if(this.order_time!=null && this.order_time.length===2){
                postObj.start_time = this.order_time[0]
                postObj.end_time = this.order_time[1]
            }
            apiOrderList(postObj)
                .then((res) => {
                    let list = []
                    let auto_close = 0
                    if (res.code == 0 && res.data) {
                        auto_close = res.data.auto_close
                        let date = Date.parse(new Date()) / 1000
                        for (let i = 0; i < res.data.list.length; i++) {
                            res.data.list[i].currentTime = date
                            res.data.list[i].startTime = date
                            res.data.list[i].endTime = res.data.list[i].create_time + auto_close
                        }
                        list = res.data.list
                        this.total = res.data.count
                    }
                    this.orderList = list
                    this.loading = false
                })
                .catch((res) => {
                    this.loading = false
                })
        },
        handlePageSizeChange(size) {
            this.pageSize = size
            this.refresh()
        },
        handleCurrentPageChange(page) {
            this.currentPage = page
            this.refresh()
        },
        refresh() {
            this.loading = true
            this.getOrderList()
        },
        operation(action, orderData) {
            let index = this.status
            switch (action) {
                case "orderPay": // 支付
                    this.orderPay(orderData)
                    break
                case "orderClose": //关闭
                    this.orderClose(orderData.order_id, () => {
                        this.refresh()
                    })
                    break
                case "memberTakeDelivery": //收货
                    this.orderDelivery(orderData.order_id, () => {
                        this.refresh()
                    })
                    break
                case "trace": //查看物流
                    this.$router.push({
                        path: "/member/logistics",
                        query: {
                            order_id: orderData.order_id
                        }
                    })
                    break
                case "memberOrderEvaluation": //评价
                    this.$router.pushToTab({
                        path: "/evaluate",
                        query: {
                            order_id: orderData.order_id
                        }
                    })
                    break
            }
        },
        orderDetail(data) {
            switch (parseInt(data.order_type)) {
                case 2:
                    // 自提订单
                    this.$router.push({
                        path: "/member/order_detail_pickup",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 3:
                    // 本地配送订单
                    this.$router.push({
                        path: "/member/order_detail_local_delivery",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                case 4:
                    // 虚拟订单
                    this.$router.push({
                        path: "/member/order_detail_virtual",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
                default:
                    this.$router.push({
                        path: "/member/order_detail",
                        query: {
                            order_id: data.order_id
                        }
                    })
                    break
            }
        },
        imageError(orderIndex, goodsIndex) {
            this.orderList[orderIndex].order_goods[goodsIndex].sku_image = this.defaultGoodsImage
        }
    }
}
</script>
<style lang="scss" scoped>
.box {
    width: 100%;
    position: relative;
}

.null-page {
    width: 100%;
    height: 730px;
    background-color: #ffffff;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 9;
}

.order-list {
    nav {
        overflow: hidden;
        padding: 10px 0;
        background: #fff;
        margin-bottom: 10px;
        border-bottom: 1px solid #eeeeee;

        li {
            float: left;

            &:nth-child(1) {
                width: 5%;
            }

            &:nth-child(2) {
                width: 30%;
            }

            &:nth-child(3) {
                width: 10%;
            }

            &:nth-child(4) {
                width: 10%;
            }

            &:nth-child(5) {
                width: 10%;
            }

            &:nth-child(6) {
                width: 10%;
            }

            &:nth-child(7) {
                width: 10%;
            }

            &:nth-child(8) {
                width: 10%;
            }
        }
    }

    .list {
        .item {
            margin-bottom: 20px;
            border: 1px solid #eeeeee;
            border-top: 0;
            overflow: hidden;

            .totalamount {
                background: #f7f7f7;
                text-align: right;
                padding: 5px 10px 5px 0;
                color: #ff547b !important;

                span {
                    margin-left: 15px;
                }
            }

            .head {
                -webkit-user-select: none;
                /*webkit浏览器*/
                -ms-user-select: none;
                /*IE10*/
                -khtml-user-select: none;
                /*早期浏览器*/
                user-select: none;
                cursor: pointer;
                width: 953px;
                height: 25px;
                padding: 8px 10px;
                background: #f7f7f7;
                font-size: 12px;
                white-space: nowrap;
                overflow-x: hidden;

                .create-time {
                    margin-right: 10px;
                }

                border-bottom: 1px solid #eeeeee;

                a {
                    margin: 0 10px 0 20px;
                }

                .order-type {
                    margin-left: 30px;
                }
            }

            .order-time {
                display: flex;
                align-items: center;
                font-size: 10px;
            }
        }

        ul {
            background-color: #fff;
            padding: 10px;
            overflow: hidden;

            li {
                float: left;
                line-height: 60px;

                &:nth-child(1) {
                    width: 5%;
                }

                &:nth-child(2) {
                    width: 30%;
                    line-height: inherit;
                    display: flex;

                    .img-wrap {
                        width: 80px;
                        height: 80px;
                        float: left;
                        margin-right: 10px;
                        cursor: pointer;
                        flex-shrink: 0;

                        span {
                            color: red;
                        }
                    }

                    .info-wrap {
                        padding-right: 10px;
                        box-sizing: border-box;
                        width: calc(100% - 100px);
                        h5 {
                            font-weight: normal;
                            font-size: $ns-font-size-base;
                            display: -webkit-box;
                            -webkit-box-orient: vertical;
                            -webkit-line-clamp: 2;
                            overflow: hidden;
                            margin-right: 10px;
                            display: inline-block;
                            cursor: pointer;

                            &:hover {
                                color: $base-color;
                            }
                        }

                        span {
                            font-size: $ns-font-size-sm;
                            color: #9a9a9a;
                        }
                    }
                }

                &:nth-child(3) {
                    width: 10%;
                    line-height: 25px;

                    span {
                        display: inline-block;

                    }
                }

                &:nth-child(4) {
                    width: 10%;
                }

                &:nth-child(5) {
                    width: 10%;

                }

                &:nth-child(6) {
                    width: 10%;

                }

                &:nth-child(7) {
                    // line-height: initial;
                    width: 10%;

                }

                &:nth-child(8) {
                    width: 15%;

                    // line-height: 30px;
                    a {
                        display: inline-block;
                        margin-left: 10px;
                    }
                }

                &:nth-child(9) {
                    display: flex;
                    justify-content: flex-end;
                    align-items: center;
                    width: 60%;

                    button {
                        margin-right: 1px;

                        &:last-child {
                            margin-bottom: 0;
                        }
                    }
                }
            }
        }
    }

    .empty-wrap {
        text-align: center;
        padding: 10px;
    }
}
.order_serch{
    display: flex;
    align-items: center;
    gap: 12px;
    margin: 12px 0;
}
.order_list_box{
    display: flex;
    flex-direction: column;
    gap: 12px;
    margin-bottom: 12px;
    .order_list_header{
        display: flex;
        align-items: center;
        background-color: white;
        border: 1px solid #e5e5e5;
        box-sizing: border-box;
        cursor: default;
        >div:not(:last-child){
            border-right: 1px solid #e5e5e5;
        }
        >div{
            flex: 1;
            height: 40px;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            >span{
                color: #333;
            }
        }
    }
    .order_list_content{
        display: flex;
        flex-direction: column;
        background-color: white;
        padding: 12px 0;
        box-sizing: border-box;
        cursor: default;
        >div:nth-child(1){
            display: flex;
            align-items: center;
            padding-left: 12px;
            gap: 12px;
            border-bottom: 1px dashed #e5e5e5;
            padding-bottom: 12px;
            margin-bottom: 12px;
            >div{
                >span{
                    color: #333;
                }
            }
        }
        >div:nth-child(2){
            display: flex;
            align-items: center;
            >div{
            flex: 1;
            display: flex;
            align-items: center;
            justify-content: center;
            box-sizing: border-box;
            overflow: hidden;
            >span{
                color: #333;
            }
        }
        }
    }
    .order_list_empty{
        display: flex;
        align-items: center;
        justify-content: center;
        height: 200px;
        background-color: white;
        >span{
            color: #999;
        }
    }
}
.text_color1{
    color: #ff547b !important;
}
.text_color2{
    color: #ff6600 !important;
    cursor: pointer;
}
</style>
